import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Axios from 'axios';
import functions from '../components/functions.vue';
export default {
  mixins: [functions],
  // components: { FinishedBox },
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');

    if (!this.getUsersProfile) {
      this.loadUserProfile();
    } else {
      this.showUserStatus = 'ok';
    }
  },
  data: function data() {
    return {
      showUserStatus: 'loading'
    };
  },
  computed: {
    getUsersProfile: function getUsersProfile() {
      return this.$store.getters.getUsersProfile(this.$route.params.userUrl);
    },
    getAchievements: function getAchievements() {
      return this.$store.getters.getAchievements;
    }
  },
  methods: {
    orderedAchievements: function orderedAchievements() {
      var achievements = [];

      if (this.getAchievements.length > 0) {
        this.getAchievements.forEach(function (el) {
          achievements.push(el);
        }); // return achievements;

        return achievements.sort(function (a, b) {
          return a.order > b.order ? 1 : -1;
        });
      }

      return false;
    },
    uzivatelZiskalAchiev: function uzivatelZiskalAchiev(idAchiev) {
      if (this.getUsersProfile.achievements.length > 0) {
        var hladaneId = function hladaneId(el) {
          return el.idAchievement == idAchiev;
        };

        var vysledok = this.getUsersProfile.achievements.findIndex(hladaneId);

        if (vysledok >= 0) {
          return this.getUsersProfile.achievements[vysledok].count;
        }
      }

      return false;
    },
    getAchievement: function getAchievement(id) {
      return this.$store.getters.getAchievement(id);
    },
    zobrazModal: function zobrazModal(achievObj) {
      this.$store.commit('setUpperModal', {
        visible: true,
        whatToShow: 'ZoomInAchieve',
        data: {
          achievObj: achievObj
        }
      });
    },
    flagLink: function flagLink(flag) {
      return this.$store.getters.URLSITE + '/photos/flags/' + flag + '_s.jpg';
    },
    openUserBestPhotosWindow: function openUserBestPhotosWindow() {
      this.$store.commit('setLowerModal', {
        visible: true,
        whatToShow: 'UserBestPhotos',
        data: {
          userUrl: this.$route.params.userUrl
        }
      });
    },
    loadUserProfile: function loadUserProfile() {
      var _this = this;

      this.$store.commit('setModalLoading', true);
      var udaje = {
        'userUrl': this.$route.params.userUrl
      };
      Axios.post(this.$store.getters.URLAJAX + '/handler.php?page=user-profile', udaje).then(function (response) {
        var res = response.data;

        if (res.return) {
          var dataNaZapis = {
            userUrl: _this.$route.params.userUrl,
            user: [],
            topPhotos: [],
            achievements: [],
            daysInCompetetion: 0
          };

          if ('user' in res) {
            dataNaZapis.user = res.user;
          }

          if ('topPhotos' in res) {
            dataNaZapis.topPhotos = res.topPhotos;
          }

          if ('best10results' in res) {
            dataNaZapis.best10results = res.best10results;
          }

          if ('achievements' in res) {
            dataNaZapis.achievements = res.achievements;
          }

          if ('daysInCompetetion' in res) {
            dataNaZapis.daysInCompetetion = parseInt(res.daysInCompetetion);
          }

          if ('lastOnline' in res) {
            dataNaZapis.lastOnline = parseInt(res.lastOnline);
          }

          _this.showUserStatus = 'ok';

          _this.$store.commit('setUsersProfile', dataNaZapis);
        } else {
          _this.showUserStatus = 'fail';

          _this.$store.commit('setErrorModal', {
            visible: true,
            data: res.returnMsg
          });

          if (res.redirect) {
            setTimeout(function () {
              _this.$store.commit('setErrorModal', {
                visible: false
              });

              _this.$router.push({
                path: '/active-contests'
              });
            }, 1500);
          }
        }
      }).catch(function (e) {
        _this.showUserStatus = 'fail';

        _this.$store.commit('setErrorModal', {
          visible: true,
          data: "#49 Network error" + e + ""
        });
      }).finally(function () {
        _this.$store.commit('setModalLoading', false);
      });
    }
  }
};